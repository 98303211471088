import IMG_LOGO from "../../assets/images/electricworksofnyc-logo.png"
import IMG_LOGO_NAME from "../../assets/images/electricworksofnyc-logo-name.png"

import IMG_YELP_LOGO from "../../assets/images/yelp-logo.png"

import IMG_GALLARY_1 from "../../assets/images/gallary/billboard-lighting.jpg"
import IMG_GALLARY_2 from "../../assets/images/gallary/cat6-termination-data-rack.jpg"
import IMG_GALLARY_3 from "../../assets/images/gallary/deli-pendants.jpg"
import IMG_GALLARY_4 from "../../assets/images/gallary/chandeller-installation.jpg"
import IMG_GALLARY_5 from "../../assets/images/gallary/custom-led-closet-lighting.jpg"
import IMG_GALLARY_6 from "../../assets/images/gallary/custom-lighting.jpg"
import IMG_GALLARY_7 from "../../assets/images/gallary/deck-lighting.jpg"
import IMG_GALLARY_8 from "../../assets/images/gallary/panel-upgrade.jpg"
import IMG_GALLARY_9 from "../../assets/images/gallary/exhaust-fan-motor-replacement.jpg"

export const generalConfigs = {
    yelpLogo: IMG_YELP_LOGO,
    logo: IMG_LOGO,
    gallary: [
        {
            img: IMG_GALLARY_1,
            height: 500,
            width: 250,
            title: "Gallary Image One"
        },
        {
            img: IMG_GALLARY_2,
            height: 500,
            width: 250,
            title: "Gallary Image Two"
        },
        {
            img: IMG_GALLARY_3,
            height: 500,
            width: 250,
            title: "Gallary Image Three"
        },
        {
            img: IMG_GALLARY_4,
            height: 500,
            width: 250,
            title: "Gallary Image Four"
        },
        {
            img: IMG_GALLARY_5,
            height: 500,
            width: 250,
            title: "Gallary Image Five"
        },
        {
            img: IMG_GALLARY_6,
            height: 500,
            width: 250,
            title: "Gallary Image Six"
        },
        {
            img: IMG_GALLARY_7,
            height: 500,
            width: 250,
            title: "Gallary Image Seven"
        },
        {
            img: IMG_GALLARY_8,
            height: 500,
            width: 250,
            title: "Gallary Image Eight"
        },
        {
            img: IMG_GALLARY_9,
            height: 500,
            width: 250,
            title: "Gallary Image Nine"
        }
    ]
}

export const metaConfigs = {
    url: `https://electricworkofnyc.com`,
    siteTitle: "Electric Work of NYC",
    shortTitle: `Forty Degree Waters`, // Used for App manifest e.g. Mobile Home Screen
    description: "Fully licensed electrical service company providing services for residential and commercial buildings across the New York City area. Schedule an appointment today by calling (347) 282-8525.",
    icon: IMG_LOGO,
    image: IMG_LOGO_NAME,
    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image
}