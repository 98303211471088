import React from 'react'

const About = () => {
  return (
    <section class="section" id="about">
              <h1 class="title">About</h1>

        <div class="container has-text-centered">
        <div class="card">
        <div class="card-image">

        <figure class="image is-3by2">
            <img class="" src="https://i.guim.co.uk/img/media/4cb1c621ec9db535d3d89cacaa68e37343fc0e09/0_0_2400_1600/master/2400.png?width=1300&quality=85&fit=max&s=e4abd72cf8975c5de6b557984e6323a8"/>
        </figure>
        </div>
        </div>

        <h2 class="subtitle">
            Aramis Rosa is the Master Electricain behind Electric Work of NYC
        </h2>
        </div>
        <br/>
        <p class="subtitle">
        Electric Work of NYC was founded by Aramis. He started as a journeyman and worked his way up to New York Electrical Master. He has over 12-years experience working on residental and commerical  sites.
        </p>
    </section>
  )
}

export default About