import React, { useState } from 'react'
import { generalConfigs } from '../../utils/config/site';



const Navbar = () => {
    const [isNavItemActive, setIsNavItemActive] = useState(false);
    const [isHamburgerActive, setIsHamburgerActive] = useState(false);

    const NavModal = () => {
        return (
            <div className={`modal ${isNavItemActive ? "is-active" : ""}`}>
                <div className="modal-background" />
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title has-text-centered">Contact Information</p>
                        <button
                            onClick={() => setIsNavItemActive(!isNavItemActive)}
                            className="delete"
                            aria-label="close"
                        />
                    </header>
                    <section className="modal-card-body">
                        <nav className="level">
                            <div class="level-item has-text-centered">
                                <div>

                                    <p class="heading" style={{ color: 'black' }}>Phone</p>

                                    <a class="button" href="tel:347-282-8525">
                                        (347) 282-8525
                                    </a>
                                </div>
                            </div>

                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading" style={{ color: 'black' }}>Email</p>

                                    <a class="button" href="mailto: contact@electricworksofnyc.com">
                                    contact@electricworksofnyc.com
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </section>
                </div>
            </div>)
    }
    return (
        <nav class="navbar is-transparent" >
            <div class="container">
                <div class="navbar-brand">
                    <a role="button" class={`navbar-burger ${isHamburgerActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" onClick={() => setIsHamburgerActive(!isHamburgerActive)}>
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                    </a>
                </div>
                <div id="navMenu" class={`navbar-menu ${isHamburgerActive ? "is-active" : ""}`}>
                    <div class={`navbar-menu ${isHamburgerActive ? "is-active" : ""}`} style={{ justifyContent: 'center' }}>
                        <a class="navbar-item" onClick={() => setIsNavItemActive(!isNavItemActive)}>
                            Contact
                        </a>
                    </div>
                    <NavModal />
                </div>
            </div>
        </nav>
    )

}


const Hero = () => {
    return (
        <section class="hero is-link is-fullheight-with-navbar">
            <div class="hero-head">
                <Navbar />
            </div>
            <div class="hero-body">
                <div class="container" style={{ textAlign: 'center' }}>
                    <img src={generalConfigs.logo} width={850} height={800} style={{ marginLeft: '3.5%' }} />
                    <p class="title">Electric Work of NYC</p>
                    <p class="subtitle">Licensed & Insured</p>
                </div>
            </div>
            <div class="hero-foot">
                <nav class="tabs is-boxed is-fullwidth">
                    <div class="container">
                        <ul>
                            <li onClick={() => document.getElementById('gallary').scrollIntoView({
                                behavior: 'smooth'
                            })}>
                                <a>Gallary</a>
                            </li>
                            <li onClick={() => document.getElementById('features').scrollIntoView({
                                behavior: 'smooth'
                            })}>
                                <a>Features</a>
                            </li>
                            <li onClick={() => document.getElementById('about').scrollIntoView({
                                behavior: 'smooth'
                            })}>
                                <a>About</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </section>
    )
}

export default Hero