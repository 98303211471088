import React from 'react'
import { generalConfigs } from '../../../utils/config/site'
import { OutboundLink } from "gatsby-plugin-google-gtag"




const Feature = () => {
    const GuardianCard = () => {
        return (
            <div class="card" style={{ width: "450px" }}>
                <OutboundLink href="https://www.theguardian.com/environment/2022/feb/24/passive-building-energy-efficiency-affordable-housing-new-york" style={{ color: 'inherit' }} target="_blank">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-left">
                                <figure class="image is-48x48">
                                    <svg viewBox="0 0 56 56">
                                        <path d="M28 0a28 28 0 1 0 28 28A28 28 0 0 0 28 0" fill="#FFFFFF"></path>
                                        <path d="M33 6.92c3.63.58 8.24 3.06 9.89 4.83v8h-1L33 7.82zm-3.34.5h-.09c-6.35 0-9.8 8.8-9.8 20.66 0 11.87 3.45 20.66 9.8 20.66h.09v.91c-9.56.65-22.42-6.63-22.09-21.58C7.23 13.14 20.09 5.86 29.66 6.51zm16.16 22.53l-3 1.3v13.44A24.26 24.26 0 0 1 33 49.52V31l-3.3-1.09V29h16.12z" fill="#052962"></path>
                                    </svg>
                                </figure>
                            </div>
                            <div class="media-content">
                                <p class="title is-4">The Guardian</p>
                                <p class="subtitle is-6">‘It’s a sanctuary’: the magic of quiet, low-cost, allergy-free ‘passive’ homes</p>
                            </div>
                        </div>
                        <div class="content">
                            Aramis Rosa was mentioned for his work in his energy-efficient passive design house
                            <hr />
                            <time datetime="2022-02-24">Thu 24 Feb 2022 05.00 EST</time>
                        </div>
                    </div>
                </OutboundLink>
            </div>
        );
    }
    const YelpCard = () => {
        return (
            <div class="card" style={{ width: "450px" }}>
                <OutboundLink href="https://www.yelp.com/biz/electric-work-of-nyc-brooklyn-3" style={{ color: 'inherit' }} target="_blank">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-left">
                                <figure class="image is-48x48">
                                    <img src={generalConfigs.yelpLogo} />
                                </figure>
                            </div>
                            <div class="media-content">
                                <p class="title is-4">Yelp</p>
                                {/* <p class="subtitle is-6">Please leave a review on Yelp we'd really appreciate it</p> */}
                            </div>

                        </div>
                        <div class="content">
                            Please leave a review on Yelp we'd really appreciate it
                        </div>
                    </div>
                </OutboundLink>
            </div>
        )

    }
    const ElectricWorkCard = () => {
        return (<div class="card" style={{ width: "450px" }}>
            <div class="card-content">
                <div class="media">
                    <div class="media-left">
                        <figure class="image is-48x48">
                            <img src={generalConfigs.logo} />
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">Best in the bussiness</p>
                        {/* <p class="subtitle is-6">We are proven to be the best in the bussiness</p> */}
                    </div>
                </div>
                <div class="content">
                    We are proven to be the best in the bussiness
                </div>
            </div>
        </div>);
    }

    return (
        <section class="section" id="features">
            <h1 class="title">Features</h1>
            <h2 class="subtitle">
                View Electric Works of NYC mentioned in other organization <strong>sections</strong>, like the one you're currently reading.
            </h2>
            <div class="tile is-ancestor">
                <div class="tile is-parent is-4">
                    <GuardianCard />

                </div>
                <div class="tile is-parent is-4">
                    <YelpCard />
                </div>
                <div class="tile is-parent is-4">
                    <ElectricWorkCard />
                </div>
            </div>
        </section>
    )
}

export default Feature