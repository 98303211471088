import React from 'react'
import { useState } from "react";
import { generalConfigs } from '../../../utils/config/site'


import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";


import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Captions from "yet-another-react-lightbox/plugins/captions";



const ReactPhotoAlbum = () => {
    const [index, setIndex] = useState(-1);

    const photos = generalConfigs.gallary.map((photo) => ({
        src: photo.img,
        width: 2400,
        height: 1600,
        alt: photo.title,
        description: photo.title
    }));

    const slides = photos.map(({ src, width, height, images }) => ({
        src,
        width,
        height
    }));


    const [fade, setFade] = React.useState(250);
    const [swipe, setSwipe] = React.useState(500);
    return (
        <>
            <PhotoAlbum
                photos={photos}
                layout="rows"
                spacing={0}
                padding={5}
                onClick={(event, photo, index) => setIndex(index)}
            />

            <Lightbox
                animation={{ fade, swipe }}
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                plugins={[Fullscreen, Thumbnails, Captions]}
            />
        </>
    );
}

const Gallary = () => {
    return (
        <section class="section" id="gallary">
            <h1 class="title">Gallary</h1>
            {/* <div class="post-feed" style={{ display: 'grid', justifyContent: 'space-between', gridTemplateColumns: 'repeat(3 , 1fr)', gridGap: '5px' }}>
                {generalConfigs.gallary.map((node) => (
                    <img src={node.img} alt={node.title} style={{ height: '250px', objectFit: 'cover', width: '500px' }} />
                ))}
            </div>
            <T/> */}
            <ReactPhotoAlbum/>
        </section>
    )
}

export default Gallary