import React from 'react'

const Footer = () => {
  return (
    <footer className="footer">

      <div className="content has-text-centered">
        <div className="container">

          <nav className="level">
            <div class="level-item">
              <p>
                Phone Number: (347) 282-8525
              </p>
            </div>
            <div class="level-item">

              <p>
                Hours: Monday - Friday: 8:00am - 5:00pm
              </p>
            </div>
            <div class="level-item">

              <p>
                Email: contact@electricworksofnyc.com
              </p>
            </div>
          </nav>
        </div>
        <div style={{marginBottom:"1rem"}}/>
        <div className="button" role="button" onClick={() => {window.scroll({ top: 0, left: 0, behavior: 'smooth' });}}>
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 12c0 6.623-5.377 12-12 12s-12-5.377-12-12 5.377-12 12-12 12 5.377 12 12zm-1 0c0 6.071-4.929 11-11 11s-11-4.929-11-11 4.929-11 11-11 11 4.929 11 11zm-11.5-4.828l-3.763 4.608-.737-.679 5-6.101 5 6.112-.753.666-3.747-4.604v11.826h-1v-11.828z"/></svg>
        &ensp;
                <span>
                    Scroll Top
                </span>
            </div>
            <br/>

        <hr style={{ color: "black" }} />
        <p>
          © {new Date().getFullYear()} {`Electric Work of NYC`}
        </p>
      </div>
    </footer>
  )
}

export default Footer