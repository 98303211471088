import * as React from "react"
import DefaultLayout from "../assets/components/layout/defaultlayout"
import "../assets/style/mystyles.scss"

const IndexPage = () => {
  return (
    <>
    <DefaultLayout></DefaultLayout>
    </>
  )
}

export default IndexPage
