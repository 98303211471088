import React from 'react'
import "../../style/mystyles.scss"
import Footer from '../Footer'
import Hero from '../Hero'
import About from '../sections/About'
import Feature from '../sections/Feature'
import Gallary from '../sections/Gallary'
import GeneralMeta from '../GeneralMeta'


const DefaultLayout = () => {
  return (
    <>
      <GeneralMeta type="WebSite"/>
      <div className="viewport">
        <Hero />
        <Gallary/>
        <Feature/>
        <About/>
        <Footer />
      </div>
    </>

  )
}

export default DefaultLayout