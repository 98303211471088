import React from 'react'
import { Helmet } from 'react-helmet'
import { metaConfigs } from '../../utils/config/site'

const GeneralMeta = ({canonical, title, description, image, type }) => {
    canonical = metaConfigs.url
    title = metaConfigs.title
    description = metaConfigs.description
    image = metaConfigs.image
    const publisherLogo = metaConfigs.icon
    let shareImage = image 

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": type,
        url: canonical,
        image: shareImage ?
            {
                "@type": `ImageObject`,
                url: shareImage,
                width: metaConfigs.shareImageWidth,
                height: metaConfigs.shareImageHeight,
            } : undefined,
        publisher: {
            "@type": `Organization`,
            name: title,
            logo: {
                "@type": `ImageObject`,
                url: publisherLogo,
                width: 60,
                height: 60,
            },
        },
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": canonical,
        },
        description,
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonical} />
                <meta property="og:site_name" content={title} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={canonical} />
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
            </Helmet>
        </>
    )
}

export default GeneralMeta